<template>

<div style="max-width:700px; margin:auto;">
  <h1>CONDICIONES GENERALES</h1>
  <h2>1. INFORMACIÓN GENERAL</h2>
  <p>La titularidad de esta actividad, la ostenta: DEVANCE VENTURES S.L. de aquí en adelante “abogadus”, provisto de CIF B09695081, y cuyos datos de contacto son:</p>
  <p>● Dirección: Pi y Margall 17. Santa Cruz de Tenerife. 38003</p>
  <p>● Teléfono de contacto: 643943764</p>
  <p>● Email de contacto: charlie@abogadus.com</p>
  <p>Este documento (así como todos otros documentos que aquí se mencionan) regula las condiciones por las que se rige el uso de esta actividad y la adquisición de servicios en el mismo (en adelante, Condiciones).</p>
  <p>Además de leer las presentes Condiciones, antes de acceder, navegar y/o usar esta interfaz, el Usuario ha de haber leído el Aviso Legal y las Condiciones Generales de Uso, incluyendo, la política de privacidad y de protección de datos de abogadus</p>
  <p>Al utilizar este servicio o al hacer y/o solicitar la adquisición de un producto y/o servicio a través del mismo el Usuario consiente quedar vinculado por estas Condiciones y por todo lo anteriormente mencionado, por lo que, si no está de acuerdo con todo ello, no debe usar este servicio.</p>
  <p>Asimismo, se informa que estas Condiciones podrían ser modificadas. El Usuario es responsable de consultarlas cada vez que acceda, navegue y/o use el servicio, ya que serán aplicables aquellas que se encuentren vigentes en el momento en que se solicite la adquisición de productos y/o servicios.</p>
  <p>Para todas las preguntas que el Usuario pueda tener en relación con las Condiciones puede ponerse en contacto con el titular utilizando los datos de contacto facilitados más arriba.</p>
  <h2>2. EL USUARIO</h2>
  <p>El acceso, la navegación y uso del Sitio Web o APP, confiere la condición de usuario (en adelante referido, indistintamente, individualmente como Usuario o conjuntamente como Usuarios), por lo</p>
  <p>que se aceptan, desde que se inicia la navegación por el Sitio Web o APP , todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso.</p>
  <p>El Usuario asume su responsabilidad de un uso correcto del servicio prestado.</p>
  <p>El Usuario declara ser mayor de 18 años y tener capacidad legal para celebrar contratos a través de este servicio.</p>
  <h2>3. PROCESO DE EJECUCIÓN DEL SERVICIO</h2>
  <p>Abogadus es una plataforma web donde los usuarios pueden contratar sesiones online con otros usuarios a través de videollamadas.</p>
  <p>Es un servicio dónde un usuario (PROFESIONAL) se conecta a través de videollamada con otro usuario (CLIENTE), por un tiempo determinado, a cambio de una remuneración.</p>
  <p>Los Usuarios pueden acceder al servicio por los medios y formas establecidos en las siguientes fases:</p>
  <p>1. Acceso al servicio: El usuario deberá seguir las indicaciones que aparezcan en pantalla en todo momento, cumplimentado los datos que las diferentes pantallas puedan solicitar. El Usuario deberá cumplimentar y/o comprobar la información que en cada paso se le solicita.</p>
  <p>2. Elección de perfil: El usuario deberá seleccionar su tipo perfil, pudiendo variar esta entre varios tipos de perfiles según el rol que desempeñe el usuario en la plataforma.</p>
  <p>3. Tipos de perfil/ Usuarios:</p>
  <p>● VISITANTE: Acceso a los perfiles de usuarios que tengan su información pública.</p>
  <p>● CLIENTE: Es un usuario registrado con verificación light que puede realizar las mismas acciones que el Visitante, y además puede adquirir servicios de otros usuarios.</p>
  <p>● PROFESIONAL: Es un usuario registrado con verificación regular que puede realizar las mismas acciones que el Cliente, y además puede ofrecer servicios a otros usuarios.</p>
  <p>4. Verificación de usuario:</p>
  <p>Light: El usuario debe completar su perfil con la siguiente información obligatoria:</p>
  <p>● Nombre y apellido/s</p>
  <p>● Teléfono y fecha de nacimiento (Debe ser mayor de 18 años)</p>
  <p>● País de residencia y nacionalidad</p>
  <p>● Moneda (Euro)</p>
  <p>Regular: El usuario debe verificar su identidad con documento identificativo (Depende del país). Esta verificación se hace a través de la plataforma. El proceso es el siguiente:</p>
  <p>● El usuario sube imagen de la cara frontal y posterior de su documento de identidad, y este se envía a una dirección segura de internet proporcionada por Mangopay donde se verifica su identidad.</p>
  <p>● El servicio nos envía una respuesta afirmativa o negativa. Si la respuesta es afirmativa, el usuario queda verificado. Si la respuesta es negativa, no queda verificado.</p>
  <p>● La información de la documentación nunca se almacena en los servidores de abogadus.</p>
  <p>5. Definición de oferta:</p>
  <p>El usuario PROFESIONAL crea su perfil público con información sobre sus servicios, así como el contenido de las sesiones online. Además, determina las posibles duraciones de las sesiones online con sus precios correspondientes.</p>
  <p>6. Solicitud del servicio:</p>
  <p>6.1. El usuario CLIENTE accede al perfil del profesional, selecciona la duración, la hora y fecha de la sesión que quiere contratar y registra su tarjeta VISA/MASTERCARD para pre-autorizar la compra. También podrá usar el saldo de su billetera virtual de abogadus para pagar por la sesión.</p>
  <p>6.2. Se envía una notificación al PROFESIONAL para que apruebe la sesión en menos de 24 horas.</p>
  <p>6.3. Durante esta etapa, siempre y cuando no quede menos de 24 horas para el comienzo de la sesión, tanto CLIENTE como PROFESIONAL pueden cancelar la compra sin penalización. En caso de cancelación, se elimina la pre-autorización. El cliente además podrá modificar la fecha de la sesión a través de la plataforma.</p>
  <p>6.4. Si el PROFESIONAL no confirma la sesión antes de la hora de comienzo ni se ha cancelado automáticamente después de 24 horas tras la solicitud de la sesión, se eliminará la pre-autorización de forma automática. En caso de pagar con la billetera virtual, el saldo se le devuelve automáticamente sin gastos ni tarifas asociadas.</p>
  <p>7. Confirmación del servicio:</p>
  <p>7.1. Una vez el PROFESIONAL aprueba la sesión a través de la plataforma, se ejecuta la pre-autorización inicial, y se abona el valor total de la sesión a la cuenta de abogadus.</p>
  <p>7.2. Durante esta etapa, la cancelación del servicio tiene un costo de cancelación.</p>
  <p>- En caso de cancelación por parte del CLIENTE, se le abona al CLIENTE la cantidad total pagada a su billetera virtual dentro de abogadus, menos el 3% sobre el valor de la sesión contratada, en concepto de costos de cancelación. La modificación de fecha no conlleva coste de cancelación.</p>
  <p>- En caso de cancelación por parte del PROFESIONAL, se le abona al CLIENTE la cantidad total pagada a su billetera virtual dentro de abogadus y se cobra al PROFESIONAL un costo de cancelación del 3% sobre el valor de la sesión contratada.</p>
  <p>- El coste de cancelación tiene un mínimo; nunca podrá ser menor a 0,50€.</p>
  <p>7.3. Abogadus cobra el 15% sobre el valor de la sesión por el servicio de reserva y uso de la plataforma; más el 3% por costos de procesamiento de pagos. Los costos de transacción tienen un mínimo; nunca podrán ser menores a 0,50€.</p>
  <p>8. Resolución del servicio</p>
  <p>En la resolución de la sesión contempla distintas casuísticas:</p>
  <p>8.1. Si el PROFESIONAL se presenta a la sesión y espera 5 minutos pero el CLIENTE no se presenta a la sesión.</p>
  <p>&nbsp;</p>
  <p>El CLIENTE recibe el 70% del pago realizado a su billetera virtual, y se le descuentan 0,70 € en concepto de servicio de agendamiento de EMITUS y el 2.1% del valor de la sesión en concepto de costos de transacción.</p>
  <p>El PROFESIONAL recibe el 30% del pago del valor de la sesión a su billetera virtual, y se le descuentan 0,30 € en concepto de servicio de agendamiento de EMITUS y el 0.9% del valor de la sesión en concepto de costos de transacción.</p>
  <p>8.2. Si el CLIENTE se presenta a la sesión y espera 5 minutos pero el PROFESIONAL no se presenta a la sesión.</p>
  <p>&nbsp;</p>
  <p>El CLIENTE recibe el 100% del pago realizado a su billetera virtual. Al PROFESIONAL se le descuenta el 3% del valor de la sesión en concepto de tarifa de cancelación. En caso de no tener saldo suficiente en su billetera virtual para cubrir el valor de la tarifa de cancelación, se debitará automáticamente cuando el saldo sea suficiente.</p>
  <p>8.3 Si tanto CLIENTE como PROFESIONAL se presentan a la sesión y se conectan al mismo tiempo por al menos el 80% de la sesión.</p>
  <p>&nbsp;</p>
  <p>El PROFESIONAL recibe el 100% del valor de la sesión a su billetera virtual, y se le descuenta 1,00 € en concepto de servicio de agendamiento de EMITUS y el 3% del valor de la sesión en concepto de costos de transacción.</p>
  <p>8.4 Si tanto CLIENTE como PROFESIONAL se presentan a la sesión y se conectan al mismo tiempo más del 30% y menos del 80% de la sesión.</p>
  <p>&nbsp;</p>
  <p>El PROFESIONAL recibe el pago por la sesión según el porcentaje de minutos conectados respecto la duración de la sesión, en su billetera virtual, y se le descuenta 1,00 € en concepto de servicio de agendamiento de EMITUS y el 3% del valor de la sesión en concepto de costos de transacción.</p>
  <p>El CLIENTE recibe un pago a su billetera virtual proporcional a los minutos restantes.</p>
  <p>8.5 Si tanto CLIENTE como PROFESIONAL se presentan a la sesión y se conectan al mismo tiempo por el 30% de la sesión o menos.</p>
  <p>El CLIENTE recibe el 70% del pago realizado a su billetera virtual, y se le descuentan 0,70 € en concepto de servicio de agendamiento de EMITUS y el 2.1% del valor de la sesión en concepto de costos de transacción.</p>
  <p>El PROFESIONAL recibe el 30% del pago del valor de la sesión a su billetera virtual, y se le descuentan 0,30 € en concepto de servicio de agendamiento de EMITUS y el 0.9% del valor de la sesión en concepto de costos de transacción.</p>
  <p>8.6 Si el CLIENTE se presenta a la sesión primero por al menos 5 minutos, y el PROFESIONAL se conecta después que el CLIENTE se haya desconectado.</p>
  <p>El CLIENTE recibe el 100% del pago realizado a su billetera virtual. Al PROFESIONAL se le descuenta el 3% del valor de la sesión en concepto de tarifa de cancelación. En caso de no tener saldo suficiente en su billetera virtual para cubrir el valor de la tarifa de cancelación, se debitará automáticamente cuando el saldo sea suficiente.</p>
  <p>&nbsp;</p>
  <p>8.7 Si el PROFESIONAL se presenta a la sesión primero por al menos 5 minutos, y el CLIENTE se conecta después que el PROFESIONAL se haya desconectado.</p>
  <p>El CLIENTE recibe el 70% del pago realizado a su billetera virtual, y se le descuentan 0,70 € en concepto de servicio de agendamiento de EMITUS y el 2.1% del valor de la sesión en concepto de costos de transacción.</p>
  <p>El PROFESIONAL recibe el 30% del pago del valor de la sesión a su billetera virtual, y se le descuentan 0,30 € en concepto de servicio de agendamiento de EMITUS y el 0.9% del valor de la sesión en concepto de costos de transacción.</p>
  <p>8.8 Si el CLIENTE y el PROFESIONAL se conectan, pero no al mismo tiempo y ninguno espera al menos 5 minutos al otro.</p>
  <p>El CLIENTE recibe el 100% del pago realizado a su billetera virtual, y se le descuentan 1,00 € en concepto de servicio de agendamiento de EMITUS y el 3% del valor de la sesión en concepto de costos de transacción</p>
  <p>8.9 Si ni PROFESIONAL ni CLIENTE se conectan a la sesión.</p>
  <p>El CLIENTE recibe el 100% del pago realizado a su billetera virtual, y se le descuentan 1,00 € en concepto de servicio de agendamiento de EMITUS y el 3% del valor de la sesión en concepto de costos de transacción</p>
  <p>9. Métodos de pago: El usuario podrá realizar el pago de su compra a través de tarjeta de débito y crédito, se usará principalmente la pasarela de pago de Mangopay, deberá tenerse en cuenta el IVA del servicio aplicado según el tipo de producto o servicio, así mismo el IVA aplicado se regirá según se determine entre cliente y profesional.</p>
  <img src="../../public/images/mangopay.png" alt="Mangopay Logo" style="width:300px; margin: 50px;">
  <p>De acuerdo con las regulaciones europeas se puede recibir dinero y realizar transacciones electrónicamente dentro del entorno de Mangopay hasta 100€. (Aplicado únicamente a clientes de verificación light)</p>
  <p>El usuario no puede retirar el dinero a su cuenta bancaria ni sobrepasar el saldo y/o transacciones electrónicas dentro del entorno Mangopay por un valor superior a 100€. (Aplicado únicamente a clientes de verificación light)</p>
  <p>Para retirar el dinero de la billetera virtual es necesario que el usuario realice una verificación regular de identidad, y facilite el</p>
  <p>número de cuenta bancaria IBAN así como la dirección, ciudad, región, país y código postal de residencia.</p>
  <p>Una vez esté registrada la información y el usuario esté verificado, este podrá solicitar una transacción a su cuenta bancaria sin coste.</p>
  <p>Las comunicaciones que intervengan durante las transacciones efectuadas, podrían ser archivadas y conservadas en los registros informatizados de abogadus con el fin de constituir un medio de prueba de las transacciones, en todo caso, respetando las condiciones razonables de seguridad y las leyes y normativas vigentes que a este respecto sean de aplicación, y particularmente atendiendo al Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD) y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, y a los derechos que asisten a los Usuarios conforme a la política de privacidad de este servicio.</p>
  <h2>4. DISPONIBILIDAD</h2>
  <p>Las operaciones están sujetas a la disponibilidad del servicio y/o a que ninguna circunstancia o causa de fuerza mayor afecte a la prestación del servicio. Si se produjeran dificultades en la ejecución del servicio, abogadus se compromete a contactar al Usuario.</p>
  <h2>5. MEDIOS TÉCNICOS PARA CORREGIR ERRORES</h2>
  <p>Se pone en conocimiento del Usuario que en caso de que detecte que se ha producido un error al introducir datos necesarios para procesar su solicitud del servicio, podrá modificar los mismos poniéndose en contacto con abogadus a través de los espacios de contacto habilitados en estas mismas condiciones, y, en su caso, a través de aquellos habilitados para contactar con el servicio de atención al cliente.</p>
  <p>De igual forma, se remite al Usuario a consultar el Aviso Legal y Condiciones Generales de Uso y, en concreto, la Política de Privacidad para recabar más información sobre cómo ejercer su derecho de rectificación según lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD) y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</p>
  <h2>6. EXONERACIÓN DE RESPONSABILIDAD</h2>
  <p>Salvo disposición legal en sentido contrario, abogadus no aceptará ninguna responsabilidad por las siguientes pérdidas, con independencia de su origen:</p>
  <p>● Cualesquiera pérdidas que no fueran atribuibles a incumplimiento alguno por su parte;</p>
  <p>Igualmente, abogadus también limita su responsabilidad en cuanto a los siguientes casos:</p>
  <p>● abogadus no se responsabiliza del contenido de los anuncios o sesiones llevadas a cabo entre los usuarios.</p>
  <p>● abogadus aplica todas las medidas concernientes a proporcionar un correcto servicio, no se responsabiliza de problemas externos, ajenos al propio servicio ofrecido.</p>
  <p>● abogadus aplica todas las medidas concernientes a proporcionar un correcto funcionamiento, sin embargo, no se responsabiliza por problemas externos a causas de fuerza mayor que ocasionen un mal funcionamiento del servicio, así como fallos técnicos que por causas fortuitas o de otra índole, que impidan un normal funcionamiento del servicio a través de internet, ante esta situación que solicita al Usuario que contacte con abogadus antes de iniciar el servicio.</p>
  <p>∙</p>
  <p>● En el caso de que un Usuario realice una operación, y ocurriera un fallo por parte del sistema el cual le impida finalizar de forma correcta, se solicita al Usuario que contacte a la mayor brevedad con abogadus, en un plazo no superior a 24 horas, de tal forma que proceda a la devolución de las cantidades depositadas, tras comprobación del servicio técnico.</p>
  <h2>7. COMUNICACIONES POR ESCRITO Y NOTIFICACIONES</h2>
  <p>Mediante el uso de este servicio, el Usuario acepta que la mayor parte de las comunicaciones con abogadus sean electrónicas (correo electrónico).</p>
  <p>A efectos contractuales, el Usuario consiente en usar este medio electrónico de comunicación y reconoce que todo contrato, notificación, información y demás comunicaciones que abogadus envíe de forma electrónica cumplen con los requisitos legales de ser por escrito. Esta condición no afectará a los derechos reconocidos por ley al Usuario.</p>
  <p>El Usuario puede enviar notificaciones y/o comunicarse con abogadus a través de los datos de contacto que en estas Condiciones se facilitan y, en su caso, a través de los espacios de contacto del Sitio Web o APP.</p>
  <p>Igualmente, salvo que se estipule lo contrario, abogadus puede contactar y/o notificar al Usuario en su correo electrónico o en la dirección postal facilitada.</p>
  <h2>8. GASTOS ADICIONALES Y GARANTÍAS</h2>
  <p>El uso del servicio está ligado a gastos adicionales a la comisión del 15% sobre el valor de la sesión que consiste en el procesamiento de pagos (3% con un mínimo de 0,50€).</p>
  <p>Se informa al usuario de la no existencia de garantía, debido a que el contrato se realiza entre profesionales y clientes directamente.</p>
  <h2>9. DESISTIMIENTO</h2>
  <p>Una vez iniciado el servicio no se dispondrá del plazo de 14 días naturales para resolver la contratación del servicio escogido.</p>
  <p>Para ejercer este derecho en caso de que sea posible, y según lo descrito anteriormente, deberá ponerse en contacto con abogadus a través de los datos de contacto ya mencionados. abogadus devolverá la suma abonada por el cliente, siendo el cliente el que se haga cargo de los gastos de transacción generados por dicha devolución. La solicitud de devolución ha de informarse previamente al email de charlie@abogadus.com</p>
  <h2>10. RENUNCIA</h2>
  <p>Ninguna renuncia de abogadus a un derecho o acción legal concreta o la falta de requerimiento por abogadus del cumplimiento estricto por el Usuario de alguna de sus obligaciones supondrá, ni una renuncia a otros derechos o acciones derivados de un contrato o de las Condiciones, ni exonera al Usuario del cumplimiento de sus obligaciones.</p>
  <p>Ninguna renuncia de abogadus a alguna de las presentes Condiciones o a los derechos o acciones derivados de un contrato surtirá efecto, a no ser que se establezca expresamente que es una renuncia y se formalice y se le comunique al Usuario por escrito.</p>
  <h2>11. NULIDAD</h2>
  <p>Si alguna de las presentes Condiciones fuese declarada nula y sin efecto por resolución firme dictada por autoridad competente, el resto de las cláusulas permanecerán en vigor, sin que queden afectadas por dicha declaración de nulidad.</p>
  <h2>12. ACUERDO COMPLETO</h2>
  <p>Las presentes Condiciones y todo documento al que se haga referencia expresa en estas constituyen el acuerdo íntegro existente entre el Usuario y abogadus en relación con el objeto de la prestación del servicio y sustituyen a cualquier otro pacto, acuerdo o promesa anterior convenida verbalmente o por escrito por las mismas partes.</p>
  <h2>13. PROTECCIÓN DE DATOS</h2>
  <p>La información o datos de carácter personal que el Usuario facilite a abogadus en el curso de una transacción, serán tratados con arreglo a lo establecido en la Política de Privacidad o de protección de datos. Al acceder, navegar y/o usar el servicio, el Usuario consiente el</p>
  <p>tratamiento de dicha información y datos y declara que toda la información o datos que facilita son veraces.</p>
  <h2>14. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h2>
  <p>El acceso, navegación y/o uso de este Sitio Web o APP y los contratos celebrados a través del mismo se regirán por la legislación europea.</p>
  <p>Leyes de aplicación en el Sitio Web o APP:</p>
  <p>● RGPD (Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas)</p>
  <p>● LSSI (Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico)</p>
  <p>Cualquier controversia, problema o desacuerdo que surja o esté relacionado con el acceso, navegación y/o uso del Sitio Web o APP, o con la interpretación y ejecución de estas Condiciones, será sometida a la jurisdicción no exclusiva de los juzgados y tribunales acordados.</p>
  <h2>15. QUEJAS Y RECLAMACIONES</h2>
  <p>El Usuario puede hacer llegar a abogadus sus quejas, reclamaciones o todo otro comentario que desee realizar a través de los datos de contacto que se facilitan al principio de estas Condiciones (Información General).</p>
  <p>Además, abogadus dispone de hojas oficiales de reclamación a disposición de los consumidores y usuarios, y que estos pueden solicitar a abogadus en cualquier momento, utilizando los datos de contacto que se facilitan al principio de estas Condiciones (Información General).</p>
  <h2>16. PLATAFORMA DE RESOLUCIÓN DE LITIGIOS</h2>
  <p>Asimismo, si entre abogadus y el Usuario emana una controversia, el Usuario como consumidor puede solicitar una solución extrajudicial de controversias, de acuerdo con el Reglamento (UE) 524/2013 del Parlamento Europeo y del Consejo, de 21 de mayo de 2013, sobre resolución de litigios en línea en materia de consumo y por el que se modifica el Reglamento (CE) 2006/2004 y la Directiva 2009/22/CE. Puede acceder a este método a través del siguiente Sitio Web o APP : http://ec.europa.eu/consumers/odr/.</p>

  <br><br><br>
  <p style="font-family: Basier circle;
    font-weight: bold;
    font-size: 30px;
    color: #111747;
    margin-left: 4%;">AVISO LEGAL</p>
  <br><br>

  <p>Con la finalidad de dar cumplimiento al artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico, informamos al usuario de nuestros datos:</p>
  <p>El responsable es DEVANCE VENTURES S.L. de aquí en adelante “abogadus”, provisto de CIF B09695081, y cuyos datos de contacto son:</p>
  <p>● Dirección: Pi y Margall 17. Santa Cruz de Tenerife. 38003</p>
  <p>● Teléfono de contacto: 643943764</p>
  <p>● Email de contacto: charlie@abogadus.com</p>
  <h2>1. Objeto</h2>
  <p>El prestador, responsable del Sitio Web o APP, pone a disposición de los usuarios el presente documento con el que pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSI-CE), así como informar a todos los usuarios del Sitio Web o APP respecto a cuáles son las condiciones de uso del Sitio Web o APP.</p>
  <p>Toda persona que acceda a este Sitio Web o APP asume el papel de usuario, comprometiéndose a la observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como a cualquier otra disposición legal que fuera de aplicación.</p>
  <p>El prestador se reserva el derecho a modificar cualquier tipo de información que pudiera aparecer en el Sitio Web o APP, sin que exista obligación de preavisar o poner en conocimiento de los usuarios dichas obligaciones, entendiéndose como suficiente con la publicación en el Sitio Web o APP del prestador.</p>
  <h2>2. Responsabilidad</h2>
  <p>El prestador se exime de cualquier tipo de responsabilidad derivada de la información publicada en su Sitio Web o APP, siempre que esta información haya sido manipulada o introducida por un tercero ajeno al mismo.</p>
  <p>El Sitio Web o APP del prestador puede utilizar cookies (pequeños archivos de información que el servidor envía al ordenador de quien accede a la página) para llevar a cabo determinadas funciones que son consideradas imprescindibles para el correcto funcionamiento y visualización del sitio. Las cookies utilizadas en el Sitio Web o APP tienen, en todo caso, carácter temporal con la única finalidad de hacer más eficaz su transmisión ulterior y desaparecen al terminar la sesión del usuario. En ningún caso se utilizarán las cookies para recoger información de carácter personal.</p>
  <p>Desde el Sitio Web o APP del cliente es posible que se redirija a contenidos de terceros sitios web. Dado que el prestador no puede controlar siempre los contenidos introducidos por los terceros en sus sitios web, éste no asume ningún tipo de responsabilidad respecto a dichos contenidos. En todo caso, el prestador manifiesta que procederá a la retirada inmediata de cualquier contenido que pudiera contravenir la legislación nacional o internacional, la moral o el orden público, procediendo a la retirada inmediata de la redirección a dicho Sitio Web o APP, poniendo en conocimiento de las autoridades competentes el contenido en cuestión.</p>
  <p>El prestador no se hace responsable de la información y contenidos almacenados, a título enunciativo, pero no limitativo, en foros, chats, generadores de blogs, comentarios, redes sociales o cualquier otro medio que permita a terceros publicar contenidos de forma independiente en la página web del prestador. No obstante, y en cumplimiento de lo dispuesto en el art. 11 y 16 de la LSSI-CE, el prestador se pone a disposición de todos los usuarios, autoridades y fuerzas de seguridad, y colaborando de forma activa en la retirada o en su caso bloqueo de todos aquellos contenidos que pudieran afectar o contravenir la legislación nacional, o internacional, derechos de</p>
  <p>terceros o la moral y el orden público. En caso de que el usuario considere que existe en el Sitio Web o APP algún contenido que pudiera ser susceptible de esta clasificación, se ruega lo notifique de forma inmediata al administrador del Sitio Web o APP.</p>
  <p>Este Sitio Web o APP ha sido revisado y probado para que funcione correctamente. En principio, puede garantizarse el correcto funcionamiento los 365 días del año, 24 horas al día. No obstante, el prestador no descarta la posibilidad de que existan ciertos errores de programación, o que acontezcan causas de fuerza mayor, catástrofes naturales, huelgas, o circunstancias semejantes que hagan imposible el acceso a la página web.</p>
  <h2>3. Propiedad Intelectual e industrial</h2>
  <p>El Sitio Web o APP, incluyendo a título enunciativo, pero no limitativo su programación, edición, compilación y demás elementos necesarios para su funcionamiento, los diseños, logotipos, texto y/o gráficos son propiedad del prestador o en su caso dispone de licencia o autorización expresa por parte de los autores. Todos los contenidos del Sitio Web o APP se encuentran debidamente protegidos por la normativa de propiedad intelectual e industrial, así como inscritos en los registros públicos correspondientes.</p>
  <p>Independientemente de la finalidad para la que fueran destinados, la reproducción total o parcial, uso, explotación, distribución y comercialización, requiere en todo caso de la autorización escrita previa por parte del prestador. Cualquier uso no autorizado previamente por parte del prestador será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.</p>
  <p>Los diseños, logotipos, texto y/o gráficos ajenos al prestador y que pudieran aparecer en el Sitio Web o APP, pertenecen a sus respectivos propietarios, siendo ellos mismos responsables de cualquier posible controversia que pudiera suscitarse respecto a los mismos. En todo caso, el prestador cuenta con la autorización expresa y previa por parte de los mismos.</p>
  <p>El prestador NO AUTORIZA expresamente a que terceros puedan redirigir directamente a los contenidos concretos del Sitio Web o APP, debiendo en todo caso redirigir al Sitio Web o APP principal del prestador.</p>
  <p>El prestador reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual, no implicando su sola mención o aparición en el Sitio Web o APP la existencia de derechos o responsabilidad alguna del prestador sobre los mismos, como tampoco respaldo, patrocinio o recomendación por parte del mismo.</p>
  <p>Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, así como sobre cualquiera de los contenidos del Sitio Web o APP, puede hacerlo a través del siguiente correo electrónico.</p>
  <h2>4. Veracidad de la información.</h2>
  <p>Toda la información que facilita el Usuario tiene que ser veraz. A estos efectos, el Usuario garantiza la autenticidad de los datos comunicados a través de los formularios para la suscripción de los Servicios. Será responsabilidad del Usuario mantener toda la información facilitada a abogadus permanentemente actualizada de forma que responda, en cada momento, a su situación real. En todo caso, el Usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause al prestador o a terceros.</p>
  <h2>5. Menores de edad.</h2>
  <p>Para el uso de los servicios, los menores de edad tienen que obtener siempre previamente el consentimiento de los padres, tutores o representantes legales, responsables últimos de todos los actos realizados por los menores a su cargo. La responsabilidad en la determinación de contenidos concretos a los cuales acceden los menores corresponde a aquellos, es por eso que, si acceden a contenidos inapropiados por Internet, se tendrán que establecer en sus ordenadores mecanismos, en particular programas informáticos, filtros y bloqueos, que permitan limitar los contenidos disponibles y, a pesar de que no sean infalibles, son de especial utilidad para controlar y restringir los materiales a los que pueden acceder los menores.</p>
  <h2>6. Obligación de hacer un uso correcto de la Web.</h2>
  <p>El Usuario se compromete a utilizar la Web de conformidad a la Ley y al presente Aviso Legal, así como a la moral y a buenas costumbres. A tal efecto, el Usuario se abstendrá de utilizar la página con finalidades ilícitas o prohibidas, lesivas de derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de equipos informáticos o documentos, archivos y toda clase de contenidos almacenados en cualquier equipo informático del prestador.</p>
  <p>En particular, y a título indicativo, pero no exhaustivo, el Usuario se compromete a no transmitir, difundir o poner a disposición de terceros informaciones, datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido o imagen, fotografías, grabaciones, software y, en general, cualquier clase de material que:</p>
  <p>(a) sea contraria, desprecie o atente contra los derechos fundamentales y las libertades públicas reconocidas constitucionalmente, en tratados internacionales y otras normas vigentes;</p>
  <p>(b) induzca, incite o promueva actuaciones delictivas, denigrantes, difamatorias, violentas o, en general, contrarias a la ley, a la moral y al orden público;</p>
  <p>(c) induzca, incite o promueva actuaciones, actitudes o pensamientos discriminatorios por razón de sexo, raza, religión, creencias, edad o condición;</p>
  <p>(d) sea contrario al derecho al honor, a la intimidad personal o familiar o a la propia imagen de las personas;</p>
  <p>(e) de cualquier manera perjudiquen la credibilidad del prestador o de terceros; y</p>
  <p>(f) constituye publicidad ilícita, engañosa o desleal.</p>
  <h2>7. Ley Aplicable y Jurisdicción</h2>
  <p>Para la resolución de todas las controversias o cuestiones relacionadas con el presente Sitio Web o APP o de las actividades en él desarrolladas, será de aplicación la legislación europea, a la que se someten expresamente las partes, siendo competentes para la resolución de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales del lugar de residencia del responsable.</p>
  <br><br><br><br><br>

</div>

</template>

<script>
export default {
  name: 'Terms',
  components: {},
  metaInfo () {
    return {
      title: 'emitus | Terms of Service',
      meta: [
        {
          name: 'description',
          content: 'The easiest and most efficient way to sell online sessions.'
        },
        {
          name: 'og:description',
          content: 'The easiest and most efficient way to sell online sessions.'
        },
        {
          name: 'site_name',
          content: 'Emitus'
        },
        {
          name: 'og:site_name',
          content: 'Emitus'
        },
        {
          name: 'image',
          content: 'https://emituscdn.com/emitus-share.jpg'
        },
        {
          name: 'og:image',
          content: 'https://emituscdn.com/emitus-share.jpg'
        },
        {
          name: 'title',
          content: 'Abogadus | Consultas Legales Online'
        },
        {
          name: 'og:title',
          content: 'Abogadus | Consultas Legales Online'
        },

        {
          name: 'MobileOptimized',
          content: 'width'
        },
        {
          name: 'HandheldFriendly',
          content: 'width'
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'width'
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black-translucent'
        },
        {
          name: 'theme-color',
          content: '#000000'
        },
        {
          name: 'apple-mobile-web-app-title',
          content: 'Emitus'
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'og:type',
          property: 'og:type',
          content: 'website'
        }
      ]
    }
  },

}
</script>

<style lang="scss" scoped>
div {
  padding-top: 10%;
}

h1 {
  font-family: Basier circle;
  font-weight: bold;
  font-size: 30px;
  color: #111747;
  margin-left: 4%;
}

h2 {
  font-family: Basier circle;
  font-size: 19px;
  margin-top: 5%;
  margin-left: 5%;
  font-weight: bolder;
  color: #111747;
}

h3 {
  margin-left: 5%;
  font-family: Basier circle;
  font-size: 15px;
  color: #111747;
}

p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Nunito";
  font-size: 13px;
  line-height: 28px;
  color: #444444;
  margin-left: 7%;
  margin-right: 7%;
}
</style>
